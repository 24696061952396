import { createI18n } from 'vue-i18n'
import messages from 'src/i18n/messages'
import dateTimeFormats from 'src/i18n/dateTimeFormats'
import { DateTime } from 'luxon'

const i18n = createI18n({
  locale: 'en-us',
  fallbackLocale: 'en-us',
  messages,
  dateTimeFormats
})

const fromNow = v => {
  if (!v) return ''
  let date = v
  if (!(v instanceof Date)) date = new Date(v)
  return DateTime.fromJSDate(date).toRelative()
}
const date = v => {
  if (!v) return ''
  let date = v
  if (!(v instanceof Date)) date = new Date(v)
  return date.toLocaleDateString()
}
const monthDay = v => {
  if (!v) return ''
  let date = v
  if (!(v instanceof Date)) date = new Date(v)
  return date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric' })
}
const datetime = v => {
  if (!v) return ''
  let date = v
  if (!(v instanceof Date)) date = new Date(v)
  return date.toLocaleString()
}

const filters = {
  date,
  datetime,
  fromNow,
  monthDay
}

export default ({ app, store }) => {
  app.use(i18n)
  // Set i18n instance on app
  app.i18n = i18n
  store.i18n = i18n
}

const i18nShim = {
  t: i18n.global.t.bind(i18n.global),
  ...i18n
}

export {
  i18nShim as i18n,
  filters
}
