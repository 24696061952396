import { QPage, Screen } from 'quasar'
import { h } from 'vue'

export default (props, { attrs, slots }) => {
  return h(QPage, {
    ...props,
    ...attrs,
    class: {
      'q-pa-lg': Screen.gt.sm,
      'q-pa-sm': Screen.lt.md
    }
  }, slots)
}
