import { Notify, LocalStorage } from 'quasar'
import { $publicApi, $api } from 'src/boot/axios'
import allSettled from 'promise.allsettled'

import createDebug from 'src/utils/debug'

import { signInWithToken, signOutUser } from 'src/boot/firebase'

import { app } from '../../main'
const debug = createDebug.extend('store/auth')

export function login ({ dispatch }, { identifier, password }) {
  return $publicApi.post('/auth/local', { identifier, password })
    .then(({ data }) => dispatch('onLoginSuccess', data))
    .catch(err => dispatch('onLoginError', err))
}

export function loginWithAccessToken ({ dispatch }, { provider, accessToken }) {
  return $publicApi.get(`/auth/${provider}/callback`, { params: { access_token: accessToken } })
    .then(({ data }) => dispatch('onLoginSuccess', data))
    .catch(err => dispatch('onLoginError', err))
}

export async function onLoginSuccess ({ commit, dispatch }, { jwt, user }) {
  try {
    const data = { jwt, ...user }
    const tenantId = LocalStorage.getItem('tenant.id') ?? user?.settings?.defaults?.organization ?? user?.organizations[0]?.id
    if (tenantId == null) {
      Notify.create({ type: 'negative', message: this.i18n.global.t('login.errors.orgFail') })
      throw new Error(`User ${user.id} is not associated with an organization.`)
    }
    LocalStorage.set('jwt', jwt)
    commit('replaceUser', data, { root: true })
    await dispatch('adminapp/setCurrentTenant', { tenantId, noReload: true }, { root: true })
    debug('Login success', data)
    Notify.create({ type: 'positive', message: this.i18n.global.t('login.success') })
    const redirectPath = this.$router?.currentRoute?.value?.query?.redirect
    const redirectQuery = this.$router?.currentRoute?.value?.query?.query
    const nextRoute = redirectPath ? { path: redirectPath, query: redirectQuery ? JSON.parse(redirectQuery) : null } : { name: 'home' }
    this.$router.push(nextRoute)
  } catch (e) {
    console.error(e)
  }
}

export function onLoginError ({ commit }, result) {
  debug('Login error', result)
  const err = result.response.status
  const message = this.i18n.global.te(`login.errors.${err}`) ? this.i18n.global.t(`login.errors.${err}`) : this.i18n.global.t('login.errors.default')
  Notify.create({ type: 'negative', message })
}

function getTokenFromLocalStorage () {
  if (!LocalStorage.has('jwt')) return null
  const jwt = LocalStorage.getItem('jwt')

  // If there is still no JWT, we can't login with a JWT...
  if (!jwt || jwt === '') return null

  try {
    const user = JSON.parse(atob(jwt.split('.')[1]))
    if (!user.id) {
      LocalStorage.remove('jwt')
      return null
    } else return jwt
  } catch (e) {
    // If we get here, the JWT is invalid...
    LocalStorage.remove('jwt')
    return null
  }
}

export async function initApp ({ commit, dispatch }) {
  const jwt = getTokenFromLocalStorage()
  dispatch('adminapp/setDarkModeFromLocalStorage', null, { root: true })
  const loginPromise = jwt
    ? $publicApi.get('/currentuser', { headers: { Authorization: `Bearer ${jwt}` } }).then(({ data }) => (data))
    // eslint-disable-next-line prefer-promise-reject-errors
    : Promise.reject('missing-jwt')

  const [loginResult] = await allSettled([loginPromise])

  if (loginResult.status === 'fulfilled') {
    await dispatch('onLoginSuccess', { user: loginResult.value, jwt })
    //
  } else {
    debug('Storage Token login failed', loginResult.reason, loginResult)
    await dispatch('adminapp/loadSettings', null, { root: true })
  }

  commit('loaded', true, { root: true })
}

export async function logout ({ dispatch, commit }) {
  dispatch('logoutFirebase')
  let logoutUrl
  try {
    const { data } = await $api('auth/logout', { params: { redirect_uri: window.location.origin } })
    logoutUrl = data.logoutUrl
  } catch (e) {
    console.error(e)
  }
  LocalStorage.remove('jwt')
  commit('replaceUser', {}, { root: true })
  logoutUrl ? window.location.href = logoutUrl : window.location.reload()
}

// handles firebase custom token verification (get/verify token)
export async function loginToFirebase ({ commit, getters }) {
  // TODO [Migration] verify this works, or replace by better way to do this
  if (!app.config.globalProperties.$firebase) {
    if (import.meta.env.DEV) console.error('[ERROR] Firebase api key is missing. Live feature will not work.')
    return
  }
  if (app.config.globalProperties.$firebaseAuth.currentUser && getters.isFirebaseLoggedIn) return
  try {
    const { data } = await $api.get('/live/token')
    const result = await signInWithToken(data)
    debug('firebase:signed in user', result)
    commit('setFirebaseLogin', true)
  } catch (e) {
    console.error(e)
  }
}

export async function logoutFirebase ({ commit, getters }) {
  if (!app.config.globalProperties.$firebase) return
  if (!getters.isFirebaseLoggedIn) return
  try {
    await signOutUser()
    debug('firebase:signed out user')
    commit('setFirebaseLogin', false)
  } catch (e) {
    console.error(e)
  }
}
