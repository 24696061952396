import { Notify, Dialog } from 'quasar'
import { i18n } from 'src/boot/i18n'

const negative = (message, opts = {}) => Notify.create({ type: 'negative', message, ...opts })
const positive = (message, opts = {}) => Notify.create({ type: 'positive', message, ...opts })

const defaultOk = { noCaps: true, autofocus: true }
const defaultCancel = { noCaps: true, flat: true, color: '' }

const confirmDelete = (name, opts = {}) => Dialog.create({
  title: i18n.t('notifications.confirmDelete'),
  message: i18n.t('notifications.confirmDelMsg', { name: `${name ? ` ${name}` : ''}` }),
  ok: { ...defaultOk, label: 'Delete', color: 'negative' },
  cancel: defaultCancel,
  ...opts
})

const confirmMove = (name, opts = {}) => Dialog.create({
  title: i18n.t('notifications.confirm'),
  message: i18n.t('notifications.confirmMoveMsg', { name: `${name ? ` ${name}` : ''}` }),
  ok: { ...defaultOk, label: 'Move', color: 'primary' },
  cancel: defaultCancel,
  ...opts
})

const confirmCloneToOrg = (name, opts = {}) => Dialog.create({
  title: i18n.t('notifications.confirm'),
  message: i18n.t('notifications.confirmCloneMsg', { name: `${name ? ` ${name}` : ''}` }),
  ok: { ...defaultOk, label: i18n.t('cta.clone'), color: 'primary' },
  cancel: defaultCancel,
  ...opts
})

const confirmDuplicate = (name, opts = {}) => Dialog.create({
  title: i18n.t('notifications.confirm'),
  message: i18n.t('notifications.confirmDuplicate', { name: `${name ? ` ${name}` : ''}` }),
  ok: { ...defaultOk, label: 'Duplicate', color: 'primary' },
  cancel: defaultCancel,
  ...opts
})

const confirmDownload = (name, opts = {}) => Dialog.create({
  title: i18n.t('notifications.confirm'),
  message: i18n.t('notifications.confirmDownload', { name: `${name ? ` ${name}` : ''}` }),
  ok: { ...defaultOk, label: i18n.t('cta.download'), color: 'primary' },
  cancel: defaultCancel,
  ...opts
})

export const $notify = {
  negative,
  positive,
  saveSuccess: opts => positive(i18n.t('notifications.saveSuccessful'), opts),
  createSuccess: opts => positive(i18n.t('notifications.createdSuccessful'), opts),
  error: opts => negative(i18n.t('notifications.error'), opts)
}

export const $dialog = {
  confirmDelete,
  confirmMove,
  confirmDuplicate,
  confirmDownload,
  confirmCloneToOrg
}

export default ({ app }) => {
  app.config.globalProperties.$notify = $notify

  // consider moving this to another boot file if it grows
  app.config.globalProperties.$dialog = $dialog
}
